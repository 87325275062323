<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.type" filterable clearable placeholder="类型" size="small" style="width: 300px;">
                        <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div class="inline mr-8">
                    <el-date-picker
                        v-model="filters.day"
                        type="date"
                        placeholder="罚款日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-date-picker
                        v-model="submitDateRange"
                        type="daterange"
                        placeholder="登记日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div> -->
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <!-- <div class="topbar">
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd()" :disabled="$store.state.org.type != 2">罚款登记</el-button>
                    </div>
                </div> -->
                <div class="datatable">
                    <el-table
                        ref="curCheckinTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="50">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="projectName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            label="分包单位名称">
                        </el-table-column>
                        <el-table-column
                            prop="roomNum"
                            align="center"
                            label="房间数量"
                            min-width="50">
                        </el-table-column>
                        <el-table-column
                            prop="roomName"
                            label="房间号"
                            align="left"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="type"
                            label="类型">
                            <template slot-scope="scope">
                                <span v-if="scope.row.type == 1">入住</span>
                                <span v-if="scope.row.type == 2">退宿</span>
                                <span v-if="scope.row.type == 3">换房</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="day"
                            align="center"
                            label="入住/退宿时间"
                            width="130">
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            align="center"
                            label="操作人"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            align="center"
                            label="操作时间"
                            width="150">
                        </el-table-column>
                        <!-- <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="150">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'

export default {
    name: 'Checklog',
    components: {},
    data() {
        return {
            typeList: [
                { id: 1, name: '入住' },
                { id: 2, name: '退房' },
                { id: 3, name: '换房' },
            ],
            supplierList: [],

            filters: {
                supplierId: '',
                type: '',
            },

            submitDateRange: [],

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();
    },
    watch: {
        submitDateRange (value) {
            if (null == value || value.length < 2) {
                this.filters.startDate = "";
                this.filters.endDate = "";
                return;
            }
            this.filters.startDate = value[0];
            this.filters.endDate = value[1];
        }
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.projectId = this.$store.state.org.id;
            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/build/supplierCheckLog', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.revenue = this.toFixed(item.revenue, 2);
                        item.createTime = item.createTime.substring(0, 16);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
    }
}
</script>

<style scoped>
</style>
